
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import { getServerSystemData } from '@/components/common-select/server-system-data'

// 团队选择组件
@Component({ name: 'ServerSystemSelector', components: {}, mixins: [] })
export default class ServerSystemSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  curValue = this.value || 0

  @Prop({
    type: String,
    default: '输入系统名称或者版本'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  userId?: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  initData (userId: number) {
    this.$emit('input', undefined)
    this.curValue = -1
    this.userId = userId
    if (this.userId) {
      this.loadDataFromServer()
    }
  }

  mounted () {
    this.loadDataFromServer()
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    getServerSystemData().then((res) => {
      this.datas = res.map((e) => {
        return {
          value: e.name
        }
      })
      this.loading = false
    })
  }
}
