
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import DataFormPageView from '@/frame/share/data-form-page.vue'
import StateDataFormPage from '@/frame/share/state-form-page'
import CollapsePanel from '@/components/collapse-panle.vue'
import StateFormItem from '@/components/state-formitem.vue'
import StateButton from '@/components/state-formbutton.vue'
import { Form } from 'view-design'
import PcServerApi, { PcServer } from '@/api/maintain/pc-server'
import ServerSystemSelector from '@/components/common-select/server-system-selector.vue'
import { getterUserInfo } from '@/store'
import StateEditor from '@/components/state-editor.vue'
import TeamResourceSelector from '@/components/common-select/team-resource-selector.vue'
import moment from 'moment'

@Component({
  name: 'dataPage',
  components: { DataFormPageView, CollapsePanel, StateFormItem, StateButton, ServerSystemSelector, StateEditor, TeamResourceSelector }
})
export default class DataPage extends StateDataFormPage<PcServer, any> {
  protected serverState=''

  firstPcServerId = 0;
  /**
   * 当前表单数据
     */
   formData: PcServer={
     mangerPort: 10001,
     httpsManagerPort: 10001
   }

   /// 硬盘大小非0校验
   validateServerStorage (rule: any, value: any, callback: any) {
     if (value <= 0) {
       callback(new Error('硬盘大小需大于0'))
     }
     callback()
   }

   /// 内存大小非0校验
   validateServerMemory (rule: any, value: any, callback: any) {
     if (value <= 0) {
       callback(new Error('内存大小需大于0G'))
     }
     callback()
   }

   /**
   * 是否开通企业用户
   */
   get opening () {
     const user = getterUserInfo()
     return user.operaExpirationTime && moment(user.operaExpirationTime).isAfter(moment.now())
   }

   /**
   * 当前编辑权限
   */
   get canEdit () {
     const user = getterUserInfo()
     return user.canOperatePcServer && (this.opening || this.firstPcServerId === this.formData.id)
   }

  rules={
    name: [
      { required: true, message: '名字不允许为空', trigger: 'blur' }
    ],
    serverStorage: [
      { type: 'number', required: true, message: '硬盘大小不允许为空', trigger: 'blur' },
      { validator: this.validateServerStorage, trigger: 'blur' }
    ],
    serverCpu: [
      { type: 'number', required: true, message: '服务器cpu不允许为空', trigger: 'blur' }
    ],
    serverMemory: [
      { type: 'number', required: true, message: '内存大小不允许为空', trigger: 'blur' },
      { validator: this.validateServerMemory, trigger: 'blur' }
    ],
    ipAddress: [
      { required: true, pattern: /^([0-9]+.){3}[0-9]+$/, message: 'ip地址不能为空', trigger: 'blur' }
    ],
    serverSystem: [
      { required: true, message: '操作系统不允许为空', trigger: 'change' }
    ],
    mangerPort: [
      { required: true, message: 'podman(http)服务端口不允许为空', trigger: 'blur', type: 'number' }
    ],
    httpsManagerPort: [
      { required: true, message: 'podman(https)服务端口不允许为空', trigger: 'blur', type: 'number' }
    ]
  }

  created () {
    this.initFromState({})

    if (!this.opening) {
      PcServerApi.getFirstPcServerId().then((res) => {
        this.firstPcServerId = res.data || 0
      })
    }
  }

  mounted () {
    ((this.$refs.ipInput as Vue).$el as Element).addEventListener('paste', (e: any) => {
      if (!(e.clipboardData && e.clipboardData.items)) return
      // 获取粘贴板数据
      const pasteData = (e.clipboardData as DataTransfer).items
      // 只拿最新的一条
      if (pasteData[0] && pasteData[0].kind === 'string') {
        pasteData[0].getAsString(text => {
          this.formData.ipAddress = text
        })
      }
    })
  }

  /* 加载指定id的表单数据 */
  protected onLoadFormData (id: number): Promise<PcServer> {
    return new Promise<PcServer>((resolve, reject) => {
      PcServerApi.getItemById(id)
        .then((response) => {
          console.log('加载')
          resolve(response.data!)
        })
        .catch((err: Error) => reject(err))
    })
  }

  /* 生成新增的表单数据 */
  onCreateNewFormData (): Promise<PcServer> {
    return new Promise<PcServer>((resolve, reject) => {
      resolve({
        id: undefined,
        name: '',
        serverCpu: 1,
        serverMemory: 1,
        serverStorageUnit: 'G',
        mangerPort: 10001,
        httpsManagerPort: 10001
      })
    })
  }

  doFormValidate (callback: ((valid?: boolean | undefined) => void)) {
    (this.$refs.form as Form).validate((valid) => callback(valid))
  }

  /* 执行新增数据保存 */
  onInsertFormData (): Promise<PcServer> {
    return new Promise<PcServer>((resolve, reject) => {
      PcServerApi.insertItem(this.formData)
        .then((response) => resolve(response.data!))
        .catch((err: Error) => reject(err))
    })
  }

  /* 保存修改数据 */
  protected onSaveFormData (): Promise<PcServer> {
    return new Promise<PcServer>((resolve, reject) => {
      PcServerApi.updateItem(this.formData)
        .then((response) => resolve(response.data!))
        .catch((err: Error) => reject(err))
    })
  }
}
