import axios from 'axios'

interface ServerSystemData{
  name: string;
}

const serverSystemDatas: ServerSystemData[]|undefined = undefined
/**
 * 获取服务器系统列表
 */
export function getServerSystemData (refresh = false): Promise<Array<ServerSystemData>> {
  return new Promise<Array<ServerSystemData>>((resolve, reject) => {
    if (serverSystemDatas && !refresh) {
      resolve(serverSystemDatas)
      return
    }
    axios.get('/server-system-data/server-system-datas.json')
      .then((response) => {
        const datas = response.data
        resolve(datas)
      }).catch(err => {
        reject(err)
      })
  })
}
